@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400&family=Montserrat:wght@300;400;500;600&family=Space+Mono&display=swap");
$alert-border-radius: 5px;
@import url('./toaster.css');


// then import toastr scss


// if you'd like to use it without importing all of bootstrap it requires

// bootstrap 4

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions


// if you'd like to use it without importing all of bootstrap it requires

// bootstrap 4

// boostrap 5

.classic_border {
  @apply border border-border rounded-lg;
}

.classic_border_bottom {
  @apply border-b border-border;
}

@for $i from 1 through 12 {
  .col-span-#{$i} {
    grid-column: span #{$i} / span #{$i};
  }
}

.hide {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.show {
  pointer-events: all;
  opacity: 1 !important;
}

.error_container {
  height: 25px;
  position: relative;
  margin-bottom: 5px;
  span {
    font-size: 10px !important;
  }

  .input-error {
    position: absolute;
    margin-left: 10px;
    color: #ee5b5b;
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #ee5b5b;
  border-width: 2px;
}

#container {
  height: 400px;
  max-width: 600px;
  margin: 0 auto;
}

.highcharts-title {
  font-weight: bold;
  letter-spacing: 0.3em;
  font-size: 3em;
  left: 28% !important;
  top: 36% !important;
  color: rgb(51, 51, 51);
  // transform: translate(-50%, -50%) !important;
  // width: 100% !important;
  // height: 100% !important;
  // z-index: -1;

  background: transparent;
}

.test {
  position: absolute;
  // top: 50% !important;
  // left: 50% !important;
  text-align: center !important;
  box-shadow: 3px 3px 5px 2px #000000;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 10px;
}

.wrapper-page {
  padding: 20px 10px 15px;
  margin-bottom: 15px;
  margin-inline: 10px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;

  &:first-child {
    margin-top: 10px;
  }
  background: white;
}

#select-dropdown-container {
  overflow: auto;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: nowrap !important;
}

.ng-value {
  display: flex;
  flex-direction: row-reverse;
  background-color: #e5e7eb !important;
  border-radius: 8px !important;
  padding: 0px 5px;
}

.ng-value-icon {
  border-right: none !important;
}

.ng-value-container {
  max-width: 86%;
  width: 160px;
  height: 31px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 0px;
  }
}
.loader-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.opacity-show {
  opacity: 100% !important;
}
.ngneat-close-dialog {
  color: white !important;
}

.break-words {
  word-break: break-all !important;
}
.notifier__notification--message {
  background: #903D9A;
  .notifier__notification-message {
      color: white;
  }
  .notifier__notification-button {
    color: white;
    fill: white;
  }
}
.visible-item {
  visibility: visible !important;
}
.hidden-item {
  visibility: hidden;
}
.ngneat-dialog-content {
  width: fit-content !important;
}